import React, {useEffect, useState} from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Input,
} from 'reactstrap';

import axios from "axios";
const backendURI = process.env.REACT_APP_LOGIN_URI;
const jwtToken = window.localStorage.getItem("jwt");
const headers = {
    Authorization: jwtToken,
    "Content-Type": "application/json"
};

export async function saveNewDashboard(name, selectedCharts) {
    const body = {
        name: name,
        charts: selectedCharts
    };

    const dashboard = await axios.post(
        `${backendURI}/dashboard_groups/create`,
        body,
        { headers })
        .then((response) => response.data);
    return dashboard;
}

export async function getCharts(setCharts) {
    const dashboards = await axios.get(
        `${backendURI}/dashboard_groups/get_all_charts`,
        { headers })

    setCharts(dashboards?.data?.entries);
}

const NewDashboardModal = ({ onFinish, newDashboardModalVisible, setNewDashboardModalVisible }) => {
    const [dashboardTitle, setDashboardTitle] = useState("");
    const [charts, setCharts] = useState([]);
    const [selectedCharts, setSelectedCharts] = useState([]);

    useEffect(() => {
        getCharts(setCharts)
    }, []);

    const onSelectChart = (id, selected) => {
        if(selected) {
            setSelectedCharts(charts => [...selectedCharts, id])
        } else {
            const newChartArray = selectedCharts.filter(cId => cId !== id)
            setSelectedCharts(newChartArray)
        }
    }

    const handleFormSubmit = async () => {
        setNewDashboardModalVisible(false);
        const newDashboard = await saveNewDashboard(dashboardTitle, selectedCharts)
        setDashboardTitle("");
        setSelectedCharts([])
        onFinish(newDashboard.id);
    };

    return (
        <Modal
            fade={false}
            key='modal'
            autoFocus={false}
            isOpen={newDashboardModalVisible}
            onCancel={() => setNewDashboardModalVisible(false)}
        >
            <ModalHeader className='border-bottom bg-secondary'>
                Criar um novo Dashboard
            </ModalHeader>
            <ModalBody>
                <Input
                    type='text'
                    placeholder='Nome do dashboard'
                    autoFocus
                    value={dashboardTitle}
                    onChange={(e) => setDashboardTitle(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleFormSubmit()}
                />
                <div className="row py-3">
                    {charts.map((chart) => {
                        return (<div key={chart.id} className="form-check">
                            <input onClick={(event) => onSelectChart(chart.id, event.target.checked)} type="checkbox" name="charts[]" value={chart.id}  /> {chart.name}
                        </div>)
                    })}
                </div>
            </ModalBody>
            <ModalFooter className='border-top bg-secondary'>
                <Button
                    color='secondary'
                    onClick={() => {
                        setNewDashboardModalVisible(false);
                    }}
                >
                    Cancelar
                </Button>
                <Button color='success' onClick={handleFormSubmit}>
                    Salvar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default NewDashboardModal;
