import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  UncontrolledDropdown,
} from 'reactstrap';
import { deleteDashboards } from '../helpers/api';

const DashboardModal = ({
  itemId,
  isOpen,
  onModalOpenChange,
  itemToDestroy,
  setItemToDestroy,
  setRender,
}) => {
  useEffect(() => {
    const close = (e) => {
      if (e.key === 'Escape') {
        onModalOpenChange(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [onModalOpenChange]);

  const removeDashboardItem = async (id) => {
    try {
      await deleteDashboards([id]);
    } finally {
      setRender((prev) => prev + 1);
    }
  };

  return (
    <Modal
      id={itemId}
      className='modal-dialog-centered'
      fade={false}
      isOpen={isOpen}
    >
      <div className='modal-header border-bottom bg-secondary'>
        <div className='modal-title text-center'>
          <h3 className='text-center'>Você quer mesmo apagar este painel?</h3>
        </div>
      </div>
      <div className='modal-body'>
        Essa operação não poderá ser desfeita, ou seja, este painel não poderá
        ser recuperado.
      </div>
      <div className='modal-footer border-top bg-secondary'>
        <Button
          color='secondary'
          data-dismiss='modal'
          type='button'
          onClick={() => {
            setItemToDestroy();
            onModalOpenChange(false);
          }}
        >
          Não
        </Button>
        <Button
          color='danger'
          type='button'
          onClick={() => {
            onModalOpenChange(false);
            removeDashboardItem(itemToDestroy);
          }}
        >
          Sim
        </Button>
      </div>
    </Modal>
  );
};

const DashboardItemDropdownMenu = ({
  itemId,
  onModalOpenChange,
  itemToDestroy,
  setItemToDestroy,
}) => (
  <DropdownMenu>
    <DropdownItem href={`/#/explore?itemId=${itemId}`}>Editar</DropdownItem>
    <DropdownItem
      href='/#/'
      itemId={itemId}
      onClick={() => {
        setItemToDestroy(itemId);
        onModalOpenChange(true);
      }}
    >
      Apagar
    </DropdownItem>
  </DropdownMenu>
);

const DashboardItemDropdown = ({
  itemId,
  onModalOpenChange,
  itemToDestroy,
  setItemToDestroy,
}) => {
  return (
    <UncontrolledDropdown direction='left' className='float-right'>
      <DropdownToggle color='primary'>Ações</DropdownToggle>
      <DashboardItemDropdownMenu
        itemId={itemId}
        onModalOpenChange={onModalOpenChange}
        itemToDestroy={itemToDestroy}
        setItemToDestroy={setItemToDestroy}
      />
    </UncontrolledDropdown>
  );
};

const DashboardItem = ({
  itemId,
  children,
  title,
  filterLink,
  isModalOpen,
  onModalOpenChange,
  itemToDestroy,
  setItemToDestroy,
  setRender,
}) => {
  return (
    <>
      <DashboardModal
        itemId={itemId}
        isOpen={isModalOpen}
        onModalOpenChange={onModalOpenChange}
        itemToDestroy={itemToDestroy}
        setItemToDestroy={setItemToDestroy}
        setRender={setRender}
      />
      <Card
        className='border border-secondary'
        style={{
          height: '100%',
          width: '100%',
          minHeight: '550px !important',
        }}
      >
        <CardHeader
          className='bg-secondary border-bottom border-secondary'
          style={{ cursor: 'grab !important' }}
        >
          <DashboardItemDropdown
            itemId={itemId}
            onModalOpenChange={onModalOpenChange}
            itemToDestroy={itemToDestroy}
            setItemToDestroy={setItemToDestroy}
          />
          <CardTitle className='h1 mb-0'>{filterLink !== null ? <a target="_blank" href={filterLink}>
            <img alt="Logo Inteligov" src="https://s3.amazonaws.com/www.zenlobby.com.assets/brands/symbols/favicon-152x152.png" style={{height: "25px"}} />
          </a> : null} {title}</CardTitle>
        </CardHeader>
        <CardBody
          className='p-3 overflow-auto'
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          {children}
        </CardBody>
      </Card>
    </>
  );
};

export default DashboardItem;
