import React from 'react';
import { withRouter, Link } from 'react-router-dom';
// reactstrap components
import {
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from 'reactstrap';
import { LINK_ZENLOBBY } from '../constants';

const IndexNavbar = withRouter(({ history, location }) => {
  return (
    <>
      <Navbar
        className='navbar-horizontal navbar-main navbar-dark bg-ig-blue'
        expand='lg'
        id='navbar-main'
      >
        <NavbarBrand 
          href={LINK_ZENLOBBY}
          target="_blank"
        >
          <img
            alt='Logo Inteligov'
            src='//s3.amazonaws.com/auth.inteligov.com.br/logo_inteligov.png'
            style={{ height: 45 }}
          />
        </NavbarBrand>
          <Nav className='mr-auto nav-fill flex-column flex-sm-row' navbar>
            <NavItem
              className={location.pathname === '/explore' ? 'active' : ''}
            >
              <NavLink
                to='/explore'
                tag={Link}
                onClick={() => {
                  history.push('/explore');
                }}
              >
                <span className='nav-link-inner--text'>
                  <i className='fas fa-flask mr-2'></i> Explorar
                </span>
              </NavLink>
            </NavItem>

            <NavItem className={location.pathname === '/' ? 'active' : ''}>
              <NavLink
                to='/'
                tag={Link}
                onClick={() => {
                  history.push('/');
                }}
              >
                <span className='nav-link-inner--text'>
                  <i className='fas fa-chart-line mr-2'></i> Seus dashboards
                </span>
              </NavLink>
            </NavItem>

            <NavItem className={location.pathname === '/help' ? 'active' : ''}>
              <NavLink
                to='/help'
                tag={Link}
                onClick={() => {
                  history.push('/help');
                }}
              >
                <span className='nav-link-inner--text'>
                  <i className='fa fa-question-circle'></i> Ajuda
                </span>
              </NavLink>
            </NavItem>
          </Nav>
      </Navbar>
    </>
  );
});

export default IndexNavbar;
