import React from "react";

const DashboardGroupConfiguration = ({onClick, groups, setSelected, selected, allowCreation}) => {
    if(!groups) return null;

    const handleChange = (event) => {
        const value = event.target.value;

        setSelected(value);
    }

    return (
        <div className="row bg-white" style={{ paddingTop: "10px" }}>
            <div className="col-md-8"></div>
            <div className={`col pull-right ${!allowCreation && "mr-5" }`}>
                <div className="form-group">
                    <label>Alterar para outro dashboard</label>
                    <select value={selected || "--"} className="form-control" onChange={handleChange}>
                        <option value="--">Geral</option>
                        {groups.map((option) => {
                            return (<option key={option.id} value={option.id}>{option.name}</option>)
                        })}

                    </select>
                </div>
            </div>
            {allowCreation && (<div className="text-right mr-3" style={{ marginTop: "8px" }}>
                <button onClick={onClick} className="btn btn-default pull-right mr-3 mt-4">
                    Criar novo dashboard
                </button>
            </div>)}
        </div>
    )
}

export default DashboardGroupConfiguration;
