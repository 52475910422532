import React, {useEffect, useState} from 'react';
import {Alert, Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import ChartRenderer from '../components/ChartRenderer';
import Dashboard from '../components/Dashboard';
import DashboardItem from '../components/DashboardItem';
import {MixPanel} from '../helpers/MixPanel';
import {getDashboardGroups, getDashboards, getDashboardsWithGroupId} from '../helpers/api';
import ModalWelcomeVideo from '../components/ModalWelcomeVideo';
import LoadingPage from '../components/LoadingPage';
import NewDashboardModal from "../components/NewDashboardModal";
import DashboardGroupConfiguration from "../components/DashboardGroupConfiguration";

const fetchData = async (selected, setDashboards, setError, setLoading) => {
    if(selected && selected !== '--') {
        getDashboardsWithGroupId(selected)
            .then((data) => {
                setDashboards(data)
            }).catch((error) => setError(error.message))
            .finally(() => setLoading(false))
    } else {
        getDashboards()
            .then((data) => {
                setDashboards(data)
            }).catch((error) => setError(error.message)).finally(() => setLoading(false))
    }


};

const Empty = () => (
    <div className='p-3 text-center container-sm'>
        <Alert className='alert-default' style={{margin: '0 auto 20px'}}>
            <h2 className='text-white mb-4'>Não há nada por aqui ainda...</h2>
            <p className='text-white'>Vamos criar seu primeiro painel?</p>
        </Alert>
        <Link to='/explore'>
            <Button className='btn-icon btn-3' color='primary' type='button'>
        <span className='btn-inner--icon'>
          <i className='fas fa-plus-circle'></i>
        </span>
                <span className='btn-inner--text'>Criar painel</span>
            </Button>
        </Link>
    </div>
);

const defaultLayout = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: i.layout.w || 4,
    h: i.layout.h || 8,
    minW: 4,
    minH: 8,
});

const DashboardPage = () => {
    const [modalOpen, setModalOpen] = useState();
    const [newDashboardModalVisible, setNewDashboardModalVisible] = useState();
    const [itemToDestroy, setItemToDestroy] = useState();
    const [loading, setLoading] = useState(true);
    const [dashboards, setDashboards] = useState([]);
    const [error, setError] = useState('');
    const [render, setRender] = useState(1);
    const [groups, setGroups] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        const fetchGroups = async () => {
            getDashboardGroups()
                .then((data) => {
                    setGroups(data)
                }).catch((error) => setError(error.message))
        };

        setLoading(true);
        fetchData(selected, setDashboards, setError, setLoading);
        fetchGroups();
        MixPanel.dashboardPageHit();
    }, [render]);

    useEffect(() => {
        if(selected) fetchData(selected, setDashboards, setError, setLoading)
    }, [selected])

    if (loading) {
        return <LoadingPage/>
    }

    if (error) {
        return (
            <Alert
                message={error.toString()}
                description={error.toString()}
                type='error'
            />
        );
    }

    const onFinishNewDashboard = (dashboardGroupId) => {
        getDashboardGroups()
            .then((data) => {
                setGroups(data)
            }).catch((error) => setError(error.message))

        setSelected(dashboardGroupId)
    }

    const dashboardItem = (item) => {
        return (
            <div id={item.id} key={item.id} data-grid={defaultLayout(item)}>
                <DashboardItem
                    key={item.id}
                    itemId={item.id}
                    filterLink={item.filter_link}
                    title={item.dashboard_config.name}
                    isModalOpen={modalOpen}
                    onModalOpenChange={setModalOpen}
                    itemToDestroy={itemToDestroy}
                    setItemToDestroy={setItemToDestroy}
                    setRender={setRender}
                >
                    <ChartRenderer vizState={item.dashboard_config.vizState}/>
                </DashboardItem>
            </div>
        )
    };

    return dashboards.length ? (
        <>
            <DashboardGroupConfiguration
                allowCreation
                setSelected={setSelected}
                selected={selected}
                groups={groups}
                onClick={() => setNewDashboardModalVisible(true)} />

            <NewDashboardModal onFinish={onFinishNewDashboard} setNewDashboardModalVisible={setNewDashboardModalVisible} newDashboardModalVisible={newDashboardModalVisible}/>

            <Dashboard dashboardItems={dashboards}>
                {dashboards.map(dashboardItem)}
            </Dashboard>
        </>

    ) : (
        <>
            <DashboardGroupConfiguration setSelected={setSelected} selected={selected} groups={groups} />
            <ModalWelcomeVideo/>
            <Empty/>
        </>
    );
};

export default DashboardPage;
