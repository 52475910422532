import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route } from 'react-router-dom';
import {
  DashboardPage,
  ExplorePage,
  HelpPage,
} from './pages';
import App from './App';

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
// plugins styles downloaded
// import './assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'select2/dist/css/select2.min.css';
import 'quill/dist/quill.core.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/argon-dashboard-pro-react.scss';
import './translation/i18n'


ReactDOM.render(
  <Router>
    <App>
      <Route key="index" exact path="/" component={DashboardPage} />
      <Route key="explore" path="/explore" component={ExplorePage} />
      <Route key="help" path="/help" component={HelpPage} />
    </App>
  </Router>, // eslint-disable-next-line no-undef
  document.getElementById('root')
);


